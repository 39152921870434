.home {
    color: #fdfaf6;
    letter-spacing: 1px;
    font-size: 50px;
    font-weight: 400;
    background-image: linear-gradient(rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 0));
}

.home h1 {
    margin-top: -360px;
    font-family: "Horizon";
    font-weight: 300;
    font-size: 10rem;
    color: #fdfaf6;
}

.speech-bubble::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 26%;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #fdfaf6 transparent transparent;
    translate: -50% 100%;
}

.speech-bubble::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 26%;
    border-style: solid;
    border-width: 17.6px 17.6px 0 0;
    border-color: #fdfaf6 transparent transparent;
    translate: calc(-50% - 0.2px) 100%;
}

.speech-bubble p {
    margin: 0;
    color: #45523e;
    text-align: center;
    font-size: 1rem;
}

/* Extra small devices (phones, less than 576px) */
@media screen and (max-width: 576px) {
    .home h1 {
        font-size: 4rem;
        padding: 0px 20px;
        margin-top: -300px;
    }
    .speech-bubble {
        display: none;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 768px) {
    .home h1 {
        font-size: 4rem;
        margin-top: -380px;
    }
    .speech-bubble {
        top: 58%;
        left: 74%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .home h1 {
        font-size: 5rem;
        margin-top: -450px;
    }
    /* .speech-bubble {
        top: 66%;
        left: 76%;
    } */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .home h1 {
        margin-top: -330px;
    }
    /* .speech-bubble {
        top: 56%;
        left: 80%;
    } */
}



@keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes tracking-in-contract {
    0% {
        letter-spacing: 1em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        letter-spacing: normal;
        opacity: 1;
    }
}
