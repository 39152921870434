@import url('./Styles/header-navbar.css');
@import url('./Styles/home.css');
@import url('./Styles/grid.css');
@import url('./Styles/button.css');
@import url('./Styles/thingstodo');
@import url('./Styles/faq.css');
@import url('./Styles/rsvp.css');
@import url('./Styles/RSVPSubmit.css');
@import url('./Styles/RSVPInput.css');

* {
    box-sizing: border-box;
}

/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 400 to 900 */

.playfair-display {
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.nunito {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Horizon';
    src: url('/src/assets/Horizon-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.App {
    text-align: center;
    font-family: "Playfair Dispaly", serif;
    font-style: normal;
    font-weight: 400;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: "Nunito", sans-serif;
    font-size: 1.2rem;
    line-height: 1.2;
    color: #45523e;
    background-color: #fdfaf6;
}

h1 {
    font-family: "Playfair Display", serif;
}

.page {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
}

.title {
    color: #fdfaf6;
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 5rem;
    letter-spacing: 1px;
    font-weight: 400;
}

.title h1 {
    font-family: "Horizon";
    font-weight: 400;
}

.paragraph {
    padding: 100px 20px;
    text-align: left;
    color: #45523e;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    background-color: #fdfaf6;
    font-style: light;
}

.paragraph h1 {
    text-align: center;
}

.paragraph p {
    text-indent: 30px;
    text-align: left;
    font-size: 1.5rem;
    line-height: 30px;
    margin-bottom: 5px;
}

.banner {
    max-width: 90%;
    text-align: center;
    z-index: 1;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.2;
    color: #fdfaf6;
    letter-spacing: 1px;
    /* font-style: italic; */
    margin: 0 auto;
}

.banner h1 {
    font-family: "Horizon";
    font-size: 10rem;
    font-weight: 400;
    margin: 0;
}

.split-paragraph {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 100px 20px;
}

.split-paragraph-text {
    padding: 100px 20px;
    text-align: left;
    flex: 2;
}

.split-paragraph-text p {
    font-size: 1.5rem;
}

.split-paragraph-img {
    margin-left: 20px;
    flex: 1;
}

.split-paragraph-img img {
    width: 100%;
    height: auto;
}

.line {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #45523e;
    margin-bottom: 25px;
    margin-top: 25px;
}

.centered-content {
    padding: 130px 20px;
    text-align: center;
    color: #45523e;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fdfaf6;
    width: 100%;
    box-sizing: border-box;
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.3rem;
}

.centered-content p {
    text-align: center;
    font-size: 1.5rem;
    line-height: 30px;
}

.split-paragraph-sisters {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin: 0 auto;
}

.centered-content-sisters {
    flex: 1;
    padding: 100px 50px;
    text-align: center;
    background-color: #fdfaf6;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #45523e;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
}

.centered-content-sisters p {
    text-align: center;
    font-size: 1.5rem;
    line-height: 30px;
}

.centered-content-sisters a {
    color: inherit;
    text-decoration: none;
}

.centered-content-sisters a:hover,
.centered-content-sisters a:focus {
    color: #74866d;
    text-decoration: none;
}

@media (max-width: 768px) {
    .split-paragraph {
        flex-direction: column-reverse;
        align-items: center;
        padding: 10px 10px;
    }

    .split-paragraph-text {
        text-align: center;
    }

    .split-paragraph-sisters {
        flex-direction: column;
        align-items: center;
        padding: 120px 20px;
    }

    .split-paragraph-img {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }

    .split-paragraph-img img {
        width: 100%;
        height: auto;
    }

    .centered-content-sisters {
        padding: 0px;
        margin-bottom: 20px;
    }

    .banner h1 {
        font-size: 5rem;
    }

    .title {
        font-size: 4rem;
    }
}


.vertical-line {
    width: 2px;
    background-color: #45523e;
    height: 100%;
    align-self: stretch;
}

.doodle {
    max-width: 35%;
    max-height: auto;
}

.travel-address {
    padding: 50px 30px;
    text-align: center;
    color: #fdfaf6;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: #45523e;
    width: 100%;
    box-sizing: border-box;
}

@keyframes slideIn {
    0% {
        transform: translateY(100%) rotateX(-60deg);
        opacity: 0;
    }

    100% {
        transform: translateY(0) rotateX(0deg);
        opacity: 1;
    }
}

@keyframes scale-in-left {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        opacity: 1;
    }
}