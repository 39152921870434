.things-outdoor {
    padding: 50px 30px 100px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    color: #636B46;
}

.dropdown.adventure-content {
    margin-bottom: 15px;
    /* overflow: hidden;  */
    padding: 25px;
}

/* .dropdown.adventure-content:hover {
    background-color: #eff2e9;
} */

.dropdown.adventure-content .question-header {
    font-weight: bold;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding-bottom: 5px; 
}

.dropdown.adventure-content .question-header::after {
    content: '';
    position: absolute;
    bottom: 0; 
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #636B46;
}

.things-answer {
    display: flex;
    flex-direction: column;
    line-height: 1.6;
}

.things-answer ul {
    margin: 5px;
    padding: 0;
}