/* Header Styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fdfaf6;
    padding: 0px 20px;
    z-index: 20;
    height: 120px;
    position: sticky;
    background-color: transparent;
    top: 0;
    box-sizing: border-box;
    width: 100%;
}

.header-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
}

.header.scrolled {
    background-color: #fdfaf6;
    color: #44533e;
}

.header.scrolled .header-title {
    text-shadow: none;
}

.header-title a:hover {
    color: #cdd6c7;
}

.header.scrolled .header-title a:hover {
    color: #74866d;
}

.header.faq {
    background-color: #45523e;
    color: #fdfaf6;
}

.header.faq a:hover {
    color: #cdd6c7;
}

.header-title h3 {
    margin: 0 20px;
    font-size: 1.5rem;
}

.header-title a {
    color: inherit;
    text-decoration: none;
}

.header-title a:hover {
    color: #45523e;
}

@media screen and (max-width: 820px) {
    .header-title h3 {
        font-size: 1.2rem; 
    }
}

@media screen and (max-width: 480px) {
    .header-title h3 {
        font-size: 1rem; 
    }
    .header-title {
        display: none;
    }
    .header.scrolled {
        color: #fdfaf6;
    }
}

.header-title a:hover::before {
    width: 100%;
}

/* NavBar Styles */
.navbar, .navbar.scrolled {
    display: none;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    z-index: 10;
    background-color: transparent;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    z-index: 15;
}

@media screen and (max-width: 480px) {
    .navbar {
        display: block; 
    }

    .menu-toggle {
        display: flex;
        position: sticky;
        top: 0; 

    }

    /* .navbar.scrolled {
    } */

    .header.scrolled .menu-toggle .line1,
    .header.scrolled .menu-toggle .line2,
    .header.scrolled .menu-toggle .line3 {
        background-color: #44533e; 
        transition: background-color 0.3s ease;
        
    }

    .header.faq.scrolled .menu-toggle .line1,
    .header.faq.scrolled .menu-toggle .line2,
    .header.faq.scrolled .menu-toggle .line3 {
        background-color: #fdfaf6; 
        transition: background-color 0.3s ease;
        
    }
    .header.scrolled .menu-toggle .line1.active,
    .header.scrolled .menu-toggle .line3.active {
        background-color: #fdfaf6;
    }
}

/* Hamburger lines for mobile menu toggle */
.line1,
.line2,
.line3 {
    width: 30px;
    height: 3px;
    background-color: #fdfaf6;
    margin: 3px 0;
    transition: 0.4s;
    z-index: 2;
}

.line1.active {
    transform: translate(0, 9px) rotate(-45deg);
    color: #fdfaf6;
}

.line2.active {
    opacity: 0;
}

.line3.active {
    transform: translate(0, -9px) rotate(45deg);
    color: #fdfaf6;
}

.menu-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(204, 60, 40, 0.95);
    z-index: 1;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.menu-backdrop.open {
    animation: slide-in-right 0.5s forwards;
    opacity: 1;
    transform: translateX(0); 
    /* display: block;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.menu-backdrop.close {
    animation: slide-out-left 0.5s forwards;
    opacity: 0;
    transform: translateX(-100%); 
    /* animation: slide-out-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-out-left {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%);
        opacity: 0;
    }
}
/* Menu Items */
.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.navbar li {
    padding: 10px 0;
}

.navbar li a {
    margin: 0 10px;
    position: relative;
    text-decoration: none;
    color: #fdfaf6;
}
