.button:link,
.button:visited {
    border: 2px solid #45523e;
    background-color: #fdfaf6;
    color: #45523e;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    border-radius: 15px;
    margin-top: 10px;
    z-index: 1;
}

.button:hover,
.button:active {
    background-color: #45523e;
    color: #fdfaf6;
}
