.grid {
    padding: 100px 20px;
	margin: 0 auto;
    max-width: 1200px;
}

.grid h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.grid-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
}

.grid-item {
    /* position: relative;  */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    aspect-ratio: 1; /* Make the grid items square */
    border-radius: 15px;
    margin-bottom: 20px; 
}

.grid-item img {
    width: 100%;
    height: 85%;
    object-fit: cover;
    border-radius: 15px;
    display: block;    flex: auto;
}

.grid-item .img-contain {
    object-fit: contain;
}

.grid-item p, .grid-item a {
    padding: 10px;
    margin-top: 10px;
    color: #444; 
    box-sizing: border-box;
    text-align: center;
    /* font-style: italic; */
    font-size: 1.3rem;
}
 
@media screen and (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}
