.guest-section h3 {
  margin: 0;
}

.guest-section {
  display: flex;
  flex-direction: column;
}

.guest-card {
  margin: 1em;
  background: rgba(81, 128, 57, 0.1);
  border-radius: 8px;
  padding: 2em;
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: center; 
  gap: 10px; 
}

@media (max-width: 768px) {
  .guest-section {
    font-size: 0.9rem;
  }
}