/* Base Styles for RSVP Container */
.rsvp-container {
    position: relative;
    background: linear-gradient(rgba(239, 242, 233, 0.8), rgba(239, 242, 233, 0.8)),
        url('../assets/iStock-1218445850.jpg') no-repeat center/cover;
    border-radius: 10px;
    padding: 40px;
    max-width: 600px;
    margin: 100px auto;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.rsvp-container h1 {
    font-family: "Horizon", sans-serif;
    font-size: 6rem;
    font-weight: 400;
    margin: 0;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.rsvp-container h2 {
    font-size: 1.8rem;
    color: #333;
    font-style: italic;
}

.rsvp-container p {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
}

.rsvp-form input,
.rsvp-form textarea,
.rsvp-form button {
    transition: all 0.3s ease;
}

/* Input Container */
.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Suggestions Dropdown */
.suggestions {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    margin: 0;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    text-align: left;
}

.suggestions li {
    padding: 8px 12px;
    cursor: pointer;
    width: 100%;
}

.suggestions li:hover {
    background-color: #f2f2f2;
}

/* Main RSVP Form Container */
.rsvp {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #45523e;
}

.label {
    font-size: 0.8rem;
    letter-spacing: 1px;
    line-height: 120%;
    padding: 1px 0;
    display: block;
    text-align: left;
    text-transform: uppercase;
    margin: 0 0 10px 0;
}

/* Input Fields */
.input {
    width: 100%;
    margin: 10px 0;
    border: none;
    font-size: 1.5rem;
    padding: 20px;
}

/* Checkbox Styling */
.checkbox-label {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}

.checkbox-label input {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    background: none;
    border: 0;
    box-shadow: inset 0 0 0 1.5px #9F9F9F;
    margin-right: 8px;
    flex-shrink: 0;
    appearance: none;
    padding: 0;
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    pointer-events: none;
}

.checkbox-label input:focus {
    outline: none;
}

.checkbox-label input:checked {
    box-shadow: inset 0 0 0 6px #74866d;
}

.checkbox-label:hover {
    background: rgba(159, 159, 159, 0.1);
}

.checkbox-label .checkbox-text {
    flex: 1;             
    text-align: left;     
    margin-left: 3px;    
    display: block;           
}

.rsvp p.suggestions {
    text-align: center !important;
    color: red;
    padding: 10px;
}

.rsvp p {
    display: block;
    font-size: 0.75rem;
    text-align: left;
    width: 100%;
}

.existing-rsvp-message {
    padding: 1rem;
    margin-bottom: 1rem;
}

.submit {
    border: 2px solid #45523e;
    background-color: #fdfaf6;
    color: #45523e;
    padding: 14px 25px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;
    border-radius: 15px;
    max-width: 200px;
    outline: none;
    box-shadow: none;
    margin: 10px;
}

.submit:hover,
.submit:active {
    background-color: #45523e;
    color: #fdfaf6;
}

/* Mobile Styles */
@media screen and (max-width: 480px) {
    .rsvp-container {
        margin: 50px 20px;
        padding: 20px;
        max-width: 90%;
    }

    .rsvp-container h1 {
        font-size: 5rem;
    }

    .rsvp-container h2 {
        font-size: 1.2rem;
    }

    .rsvp-container p {
        font-size: 0.9rem;
    }

    .input {
        padding: 15px;
        font-size: 1rem;
    }

    .submit {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}

/* Tablet Styles */
@media (max-width: 768px) {
    .label {
        font-size: 0.75rem;
    }

    .input {
        font-size: 1.2rem;
    }

    .checkbox-label {
        font-size: 0.65rem;
    }

    .checkbox-label .checkbox-text {
        text-align: left;   
    }
}