body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.header {
  color: #fdfaf6;
  z-index: 20;
  box-sizing: border-box;
  background-color: #0000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 0 20px;
  display: flex;
  position: sticky;
  top: 0;
}

.header-title {
  text-align: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.header.scrolled {
  color: #44533e;
  background-color: #fdfaf6;
}

.header.scrolled .header-title {
  text-shadow: none;
}

.header.scrolled .header-title a:hover {
  color: #74866d;
}

.header.faq {
  color: #fdfaf6;
  background-color: #45523e;
}

.header.faq a:hover {
  color: #cdd6c7;
}

.header-title h3 {
  margin: 0 20px;
  font-size: 1.5rem;
}

.header-title a {
  color: inherit;
  text-decoration: none;
}

.header-title a:hover {
  color: #45523e;
}

@media screen and (width <= 820px) {
  .header-title h3 {
    font-size: 1.2rem;
  }
}

@media screen and (width <= 480px) {
  .header-title h3 {
    font-size: 1rem;
  }

  .header-title {
    display: none;
  }

  .header.scrolled {
    color: #fdfaf6;
  }
}

.header-title a:hover:before {
  width: 100%;
}

.navbar, .navbar.scrolled {
  z-index: 10;
  background-color: #0000;
  border-radius: 5px;
  justify-content: flex-end;
  padding: 10px;
  display: flex;
}

.menu-toggle {
  cursor: pointer;
  z-index: 15;
  flex-direction: column;
  display: none;
}

@media screen and (width <= 480px) {
  .navbar {
    display: block;
  }

  .menu-toggle {
    display: flex;
    position: sticky;
    top: 0;
  }

  .header.scrolled .menu-toggle .line1, .header.scrolled .menu-toggle .line2, .header.scrolled .menu-toggle .line3 {
    background-color: #44533e;
    transition: background-color .3s;
  }

  .header.faq.scrolled .menu-toggle .line1, .header.faq.scrolled .menu-toggle .line2, .header.faq.scrolled .menu-toggle .line3 {
    background-color: #fdfaf6;
    transition: background-color .3s;
  }

  .header.scrolled .menu-toggle .line1.active, .header.scrolled .menu-toggle .line3.active {
    background-color: #fdfaf6;
  }
}

.line1, .line2, .line3 {
  z-index: 2;
  background-color: #fdfaf6;
  width: 30px;
  height: 3px;
  margin: 3px 0;
  transition: all .4s;
}

.line1.active {
  color: #fdfaf6;
  transform: translate(0, 9px)rotate(-45deg);
}

.line2.active {
  opacity: 0;
}

.line3.active {
  color: #fdfaf6;
  transform: translate(0, -9px)rotate(45deg);
}

.menu-backdrop {
  z-index: 1;
  opacity: 0;
  background-color: #cc3c28f2;
  width: 100%;
  height: 100%;
  transition: transform .5s, opacity .5s;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.menu-backdrop.open {
  opacity: 1;
  animation: .5s forwards slide-in-right;
  transform: translateX(0);
}

.menu-backdrop.close {
  opacity: 0;
  animation: .5s forwards slide-out-left;
  transform: translateX(-100%);
}

@keyframes slide-in-right {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  from {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.menu {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar li {
  padding: 10px 0;
}

.navbar li a {
  color: #fdfaf6;
  margin: 0 10px;
  text-decoration: none;
  position: relative;
}

.home {
  color: #fdfaf6;
  letter-spacing: 1px;
  background-image: linear-gradient(#000000bd, #0000);
  font-size: 50px;
  font-weight: 400;
}

.home h1 {
  color: #fdfaf6;
  margin-top: -360px;
  font-family: Horizon;
  font-size: 10rem;
  font-weight: 300;
}

.speech-bubble:before {
  content: "";
  border-top: 20px solid #fdfaf6;
  border-bottom: 0 solid #0000;
  border-left: 0 solid #0000;
  border-right: 20px solid #0000;
  position: absolute;
  bottom: 0;
  left: 26%;
  translate: -50% 100%;
}

.speech-bubble:after {
  content: "";
  border-top: 17.6px solid #fdfaf6;
  border-bottom: 0 solid #0000;
  border-left: 0 solid #0000;
  border-right: 17.6px solid #0000;
  position: absolute;
  bottom: 0;
  left: 26%;
  translate: calc(-50% - .2px) 100%;
}

.speech-bubble p {
  color: #45523e;
  text-align: center;
  margin: 0;
  font-size: 1rem;
}

@media screen and (width <= 576px) {
  .home h1 {
    margin-top: -300px;
    padding: 0 20px;
    font-size: 4rem;
  }

  .speech-bubble {
    display: none;
  }
}

@media (width >= 576px) and (width <= 768px) {
  .home h1 {
    margin-top: -380px;
    font-size: 4rem;
  }

  .speech-bubble {
    top: 58%;
    left: 74%;
  }
}

@media (width >= 768px) and (width <= 991.98px) {
  .home h1 {
    margin-top: -450px;
    font-size: 5rem;
  }
}

@media (width >= 992px) and (width <= 1199.98px) {
  .home h1 {
    margin-top: -330px;
  }
}

@keyframes bounce-in-top {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateY(-500px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(-65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: .6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.grid {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
}

.grid h2 {
  margin-bottom: 40px;
  font-size: 2.5rem;
}

.grid-container {
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: grid;
}

.grid-item {
  text-align: center;
  aspect-ratio: 1;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  overflow: hidden;
}

.grid-item img {
  object-fit: cover;
  border-radius: 15px;
  flex: auto;
  width: 100%;
  height: 85%;
  display: block;
}

.grid-item .img-contain {
  object-fit: contain;
}

.grid-item p, .grid-item a {
  color: #444;
  box-sizing: border-box;
  text-align: center;
  margin-top: 10px;
  padding: 10px;
  font-size: 1.3rem;
}

@media screen and (width <= 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.button:link, .button:visited {
  color: #45523e;
  text-align: center;
  z-index: 1;
  background-color: #fdfaf6;
  border: 2px solid #45523e;
  border-radius: 15px;
  margin-top: 10px;
  padding: 14px 25px;
  font-size: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:hover, .button:active {
  color: #fdfaf6;
  background-color: #45523e;
}

.things-outdoor {
  text-align: left;
  align-items: left;
  color: #636b46;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 30px 100px;
  display: flex;
}

.dropdown.adventure-content {
  margin-bottom: 15px;
  padding: 25px;
}

.dropdown.adventure-content .question-header {
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  font-weight: bold;
  display: flex;
  position: relative;
}

.dropdown.adventure-content .question-header:after {
  content: "";
  background-color: #636b46;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.things-answer {
  flex-direction: column;
  line-height: 1.6;
  display: flex;
}

.things-answer ul {
  margin: 5px;
  padding: 0;
}

.faq-container {
  z-index: 10;
  background-color: #fdfaf6;
  min-height: 100vh;
  margin-top: -120px;
  padding: 100px 20px;
  position: relative;
}

.faq-content {
  text-align: left;
  align-items: left;
  color: #636b46;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
}

.faq-content h1 {
  text-align: center;
  margin-bottom: 40px;
  font-size: 3rem;
}

.dropdown.faq {
  margin-bottom: 30px;
}

.question-header {
  cursor: pointer;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.rotate {
  margin-right: 15px;
  padding-top: 7px;
  transition: all .3s;
  transform: rotate(180deg);
}

.arrow {
  margin-right: 15px;
  padding-bottom: 8px;
  transition: all .3s;
}

.answer {
  font-size: 1.2rem;
  line-height: 1.6;
  display: none;
}

.question-header.active + .answer {
  text-align: left;
  justify-content: left;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 20px;
  display: flex;
}

.rsvp-container {
  text-align: center;
  opacity: 0;
  background: linear-gradient(#eff2e9cc, #eff2e9cc), url("iStock-1218445850.e8ec4d06.jpg") center / cover no-repeat;
  border-radius: 10px;
  max-width: 600px;
  margin: 100px auto;
  padding: 40px;
  animation: 1s forwards fadeIn;
  position: relative;
  box-shadow: 0 4px 10px #0000001a;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rsvp-container h1 {
  letter-spacing: 1px;
  text-shadow: 1px 1px 2px #0000001a;
  margin: 0;
  font-family: Horizon, sans-serif;
  font-size: 6rem;
  font-weight: 400;
}

.rsvp-container h2 {
  color: #333;
  font-size: 1.8rem;
  font-style: italic;
}

.rsvp-container p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.rsvp-form input, .rsvp-form textarea, .rsvp-form button {
  transition: all .3s;
}

.input-container {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.suggestions {
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  box-shadow: 0 2px 5px #00000026;
}

.suggestions li {
  cursor: pointer;
  width: 100%;
  padding: 8px 12px;
}

.suggestions li:hover {
  background-color: #f2f2f2;
}

.rsvp {
  color: #45523e;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  display: flex;
}

.label {
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  margin: 0 0 10px;
  padding: 1px 0;
  font-size: .8rem;
  line-height: 120%;
  display: block;
}

.input {
  border: none;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  font-size: 1.5rem;
}

.checkbox-label {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border-radius: 50px;
  align-items: center;
  padding: 5px;
  display: flex;
}

.checkbox-label input {
  appearance: none;
  pointer-events: none;
  background: none;
  border: 0;
  border-radius: 10px;
  flex-shrink: 0;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  padding: 0;
  transition: box-shadow .15s cubic-bezier(.95, .15, .5, 1.25);
  box-shadow: inset 0 0 0 1.5px #9f9f9f;
}

.checkbox-label input:focus {
  outline: none;
}

.checkbox-label input:checked {
  box-shadow: inset 0 0 0 6px #74866d;
}

.checkbox-label:hover {
  background: #9f9f9f1a;
}

.checkbox-label .checkbox-text {
  text-align: left;
  flex: 1;
  margin-left: 3px;
  display: block;
}

.rsvp p.suggestions {
  color: red;
  padding: 10px;
  text-align: center !important;
}

.rsvp p {
  text-align: left;
  width: 100%;
  font-size: .75rem;
  display: block;
}

.existing-rsvp-message {
  margin-bottom: 1rem;
  padding: 1rem;
}

.submit {
  color: #45523e;
  cursor: pointer;
  text-align: center;
  max-width: 200px;
  box-shadow: none;
  background-color: #fdfaf6;
  border: 2px solid #45523e;
  border-radius: 15px;
  outline: none;
  margin: 10px;
  padding: 14px 25px;
  font-size: 1rem;
  text-decoration: none;
}

.submit:hover, .submit:active {
  color: #fdfaf6;
  background-color: #45523e;
}

@media screen and (width <= 480px) {
  .rsvp-container {
    max-width: 90%;
    margin: 50px 20px;
    padding: 20px;
  }

  .rsvp-container h1 {
    font-size: 5rem;
  }

  .rsvp-container h2 {
    font-size: 1.2rem;
  }

  .rsvp-container p {
    font-size: .9rem;
  }

  .input {
    padding: 15px;
    font-size: 1rem;
  }

  .submit {
    padding: 10px 20px;
    font-size: .9rem;
  }
}

@media (width <= 768px) {
  .label {
    font-size: .75rem;
  }

  .input {
    font-size: 1.2rem;
  }

  .checkbox-label {
    font-size: .65rem;
  }

  .checkbox-label .checkbox-text {
    text-align: left;
  }
}

.guest-section h3 {
  margin: 0;
}

.guest-section {
  flex-direction: column;
  display: flex;
}

.guest-card {
  background: #5180391a;
  border-radius: 8px;
  width: 100%;
  margin: 1em;
  padding: 2em;
}

.button-container {
  justify-content: center;
  gap: 10px;
  display: flex;
}

@media (width <= 768px) {
  .guest-section {
    font-size: .9rem;
  }
}

.suggestions {
  text-align: center;
  background-color: #5180391a;
  border: none;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
}

.suggestions h3 {
  text-align: center;
  margin: 10px;
}

.suggestions p {
  text-align: center;
  margin: 5px;
  font-size: 14px;
}

.suggestions-list {
  text-align: center;
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.suggestions-list li {
  box-shadow: none;
  outline: none;
  margin-bottom: 5px;
}

.suggestions-list li button {
  color: #45523e;
  text-align: center;
  z-index: 1;
  box-shadow: none;
  background-color: #fdfaf6;
  border: 2px solid #45523e;
  border-radius: 13px;
  outline: none;
  margin-top: 10px;
  padding: 14px 25px;
  font-size: .9rem;
  text-decoration: none;
  display: inline-block;
}

.suggestions-list li:hover {
  background-color: #0000 !important;
}

.suggestions-list li button:hover, .suggestions-list li button:focus, .suggestions-list li button:active {
  color: #fdfaf6;
  box-shadow: none;
  background-color: #45523e;
  outline: none;
}

.no-suggestions {
  background: #5180391a;
  border-radius: 4px;
  margin: 10px;
  font-size: 10px;
}

* {
  box-sizing: border-box;
}

.playfair-display {
  font-optical-sizing: auto;
  font-family: Playfair Display, serif;
  font-style: normal;
  font-weight: 400;
}

.nunito {
  font-optical-sizing: auto;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: Horizon;
  src: url("Horizon-Regular.3d69c8f7.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
  font-family: Playfair Dispaly, serif;
  font-style: normal;
  font-weight: 400;
}

body {
  color: #45523e;
  background-color: #fdfaf6;
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  font-size: 1.2rem;
  line-height: 1.2;
}

h1 {
  font-family: Playfair Display, serif;
}

.page {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.title {
  color: #fdfaf6;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 5rem;
  font-weight: 400;
}

.title h1 {
  font-family: Horizon;
  font-weight: 400;
}

.paragraph {
  text-align: left;
  color: #45523e;
  max-width: 800px;
  font-style: light;
  background-color: #fdfaf6;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 100px 20px;
  display: flex;
}

.paragraph h1 {
  text-align: center;
}

.paragraph p {
  text-indent: 30px;
  text-align: left;
  margin-bottom: 5px;
  font-size: 1.5rem;
  line-height: 30px;
}

.banner {
  text-align: center;
  z-index: 1;
  color: #fdfaf6;
  letter-spacing: 1px;
  max-width: 90%;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
}

.banner h1 {
  margin: 0;
  font-family: Horizon;
  font-size: 10rem;
  font-weight: 400;
}

.split-paragraph {
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  padding: 100px 20px;
  display: flex;
}

.split-paragraph-text {
  text-align: left;
  flex: 2;
  padding: 100px 20px;
}

.split-paragraph-text p {
  font-size: 1.5rem;
}

.split-paragraph-img {
  flex: 1;
  margin-left: 20px;
}

.split-paragraph-img img {
  width: 100%;
  height: auto;
}

.line {
  background-color: #45523e;
  width: 100%;
  height: 1px;
  margin-top: 25px;
  margin-bottom: 25px;
  bottom: 0;
  left: 0;
}

.centered-content {
  text-align: center;
  color: #45523e;
  box-sizing: border-box;
  background-color: #fdfaf6;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 130px 20px;
  font-size: 1.3rem;
  display: flex;
}

.centered-content p {
  text-align: center;
  font-size: 1.5rem;
  line-height: 30px;
}

.split-paragraph-sisters {
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.centered-content-sisters {
  text-align: center;
  color: #45523e;
  box-sizing: border-box;
  background-color: #fdfaf6;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 100px 50px;
  display: flex;
}

.centered-content-sisters p {
  text-align: center;
  font-size: 1.5rem;
  line-height: 30px;
}

.centered-content-sisters a {
  color: inherit;
  text-decoration: none;
}

.centered-content-sisters a:hover, .centered-content-sisters a:focus {
  color: #74866d;
  text-decoration: none;
}

@media (width <= 768px) {
  .split-paragraph {
    flex-direction: column-reverse;
    align-items: center;
    padding: 10px;
  }

  .split-paragraph-text {
    text-align: center;
  }

  .split-paragraph-sisters {
    flex-direction: column;
    align-items: center;
    padding: 120px 20px;
  }

  .split-paragraph-img {
    justify-content: center;
    width: 100%;
    margin-left: 0;
    display: flex;
  }

  .split-paragraph-img img {
    width: 100%;
    height: auto;
  }

  .centered-content-sisters {
    margin-bottom: 20px;
    padding: 0;
  }

  .banner h1 {
    font-size: 5rem;
  }

  .title {
    font-size: 4rem;
  }
}

.vertical-line {
  background-color: #45523e;
  align-self: stretch;
  width: 2px;
  height: 100%;
}

.doodle {
  max-width: 35%;
  max-height: auto;
}

.travel-address {
  text-align: center;
  color: #fdfaf6;
  box-sizing: border-box;
  background-color: #45523e;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 30px;
  display: flex;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100%)rotateX(-60deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0)rotateX(0);
  }
}

@keyframes scale-in-left {
  0% {
    transform-origin: 0%;
    opacity: 1;
    transform: scale(0);
  }

  100% {
    transform-origin: 0%;
    opacity: 1;
    transform: scale(1);
  }
}
/*# sourceMappingURL=index.ef53ca1f.css.map */
