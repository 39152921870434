.faq-container {
    background-color: #fdfaf6;
    margin-top: -120px;
    min-height: 100vh;
    position: relative;
    z-index: 10;
    padding: 100px 20px;
}

.faq-content {
    padding-top: 50px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    max-width: 800px;
    margin: 0 auto;
    color: #636B46;
}

.faq-content h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 3rem;
}

.dropdown.faq {
    margin-bottom: 30px;
}

.question-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
}

.rotate {
    transform: rotate(180deg);
    transition: 0.3s ease;
    margin-right: 15px;
    padding-top: 7px;
}

.arrow {
    transition: 0.3s ease;
    margin-right: 15px;
    padding-bottom: 8px;
}

.answer {
    display: none;
    font-size: 1.2rem;
    line-height: 1.6;
}

.question-header.active+.answer {
    display: flex;
    justify-content: left;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
}
