.suggestions {
    background-color: rgba(81, 128, 57, 0.1);
    border-radius: 4px;
    margin: 10px;
    padding: 10px;
    text-align: center;
    border: none;
}

.suggestions h3 {
    margin: 10px;
    text-align: center;
}

.suggestions p {
    margin: 5px;
    font-size: 14px;
    text-align: center;
}

.suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    outline: none;
    box-shadow: none;
}

.suggestions-list li {
    margin-bottom: 5px;
    outline: none;
    box-shadow: none;
}

.suggestions-list li button {
    border: 2px solid #45523e;
    background-color: #fdfaf6;
    color: #45523e;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.9rem;
    border-radius: 13px;
    margin-top: 10px;
    z-index: 1;
    outline: none;
    box-shadow: none;
}

.suggestions-list li:hover {
    background-color: transparent !important;
}

.suggestions-list li button:hover,
.suggestions-list li button:focus,
.suggestions-list li button:active {
    background-color: #45523e;
    color: #fdfaf6;
    outline: none;
    box-shadow: none;
}

.no-suggestions {
    background: rgba(81, 128, 57, 0.1);
    border-radius: 4px;
    margin: 10px;
    font-size: 10px;
}